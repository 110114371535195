@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&display=swap');

:root {
  --light: #deca91;
  --dark: #2c302e;
  --blue: #0a5770;
  --pink: #f4a4b0;

  --light-faint: #deca9188;
  --dark-faint: #2c302e88;
  --blue-faint: #0a577088;
  --pink-faint: #f4a4b088;

  --light-very-faint: #deca9111;
  --dark-very-faint: #2c302e11;
  --blue-very-faint: #0a577011;
  --pink-very-faint: #f4a4b011;
}

* {
  margin: 0;
  font-family: 'Nunito Sans';
  color: var(--light);
}

body {
  box-sizing: border-box;
  background-color: var(--pink-very-faint);
}

.navbar {
  background-color: var(--dark);
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid var(--light);
}

.navbar .links {
  margin-left: auto;

}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}

.navbar a:hover {
  color: var(--pink);
}

.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

.image-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid var(--light-faint);
  width: 260px;
  height: 200px;
  border: 4px solid var(--dark);
  border-radius: 25px;
  background-color: var(--light);
}

.image-preview:hover {
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}

.image-preview h2 {
  font-size: 20px;
  color: var(--dark);
  margin-bottom: 8px;
}

.image-preview img {
  margin: 7px auto;
  display: block;
  width: 240px;
  height: 140px;
  border: 2px solid var(--dark-faint);
  border-radius: 2px;
  box-shadow: 1px 1px 1px var(--blue-faint);
}